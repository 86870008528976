import ScrollAnimation from 'react-animate-on-scroll'

function Teams() {
    return (
        <section className="teams" id="teams">
            <div className="max-width">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                    <h2 className="title">My team</h2>
                </ScrollAnimation>
                <div className="carousel owl-carousel">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="card">
                            <div className="box">
                                <img src="./Assets/Images/teams_sharad.jpg" alt="" srcSet="" />
                                <div className="text">Sharad Bhandari</div>
                                <p>Back-end Developer</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="card">
                            <div className="box">
                                <img src="./Assets/Images/teams_upreti.jpg" alt="" srcSet="" />
                                <div className="text">Amrit Upreti</div>
                                <p>Front-end Developer</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="card">
                            <div className="box">
                                <img src="./Assets/Images/teams_pangeni.jpg" alt="" srcSet="" />
                                <div className="text">Amrit Pangeni</div>
                                <p>Security Expert</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="card">
                            <div className="box">
                                <img src="./Assets/Images/teams_shreeraj.jpg" alt="" srcSet="" />
                                <div className="text">Shreeraj Sapkota</div>
                                <p>Cloud Expert</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="card">
                            <div className="box">
                                <img src="./Assets/Images/teams_rajesh.jpg" alt="" srcSet="" />
                                <div className="text">Rajesh Giri</div>
                                <p>Cloud Expert</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </section>
    )
}

export default Teams