import React from 'react';
import Footer from './Footer/footer';
import Navbar from './Navbar/Navbar';
import Pages from './Pages/Pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Container() {
    return (
        <>
            <ToastContainer/>
            <Navbar />
            <Pages />
            <Footer />
        </>
    )
}

export default Container