import React from 'react';

function Footer() {
    return (
        <footer>
            <span>Created By <a href="#home">HemantChettri</a> | <span className="far fa-copyright"></span> 2022 All rights reserved.</span>
        </footer>
    )
}

export default Footer;