import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import showToast from '../Utils/Toast';
import ScrollAnimation from 'react-animate-on-scroll';

function Contact() {
    const formRef = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        sendEmail()
        formRef.current.reset()
    }

    const sendEmail = async () => {
        const response = await emailjs.sendForm('service_ruq05yl', 'template_60bn9sf', formRef.current, '-x7pPmYOBWhIhv9Ic')
        if (response.status === 200) {
            showToast('Message sent successfully', 'success')
        } else {
            showToast('Message failed to send', 'error')
        }
    };

    return (
        <section className="contact" id="contact">
            <div className="max-width">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                    <h2 className="title">Contact me</h2>
                </ScrollAnimation>
                <div className="contact-content">
                    <div className="column left">
                        <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                            <div className="text">Get in Touch</div>
                            <p>
                                If you have any questions or would like to get in touch,
                                please fill out the form below and I will get back to you
                                as soon as possible. Or you can contact me directly at the
                                email address or phone number listed below.
                                I look forward to hearing from you!
                            </p>
                            <div className="icons">
                                <div className="row">
                                    <i className="fas fa-user"></i>
                                    <div className="info">
                                        <div className="head">Name</div>
                                        <div className="sub-title">Hemant Chettri</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <i className="fas fa-map-marker"></i>
                                    <div className="info">
                                        <div className="head">Address</div>
                                        <div className="sub-title">Kathmandu, Nepal</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <i className="fas fa-envelope"></i>
                                    <div className="info">
                                        <div className="head">Email</div>
                                        <div className="sub-title">hemantt.chettri@gmail.com</div>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="column right">
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                            <div className="text">Message me</div>
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <div className="fields">
                                    <div className="field name">
                                        <input type="text" placeholder="Name" name="name" required />
                                    </div>
                                    <div className="field email">
                                        <input type="email" placeholder="Email" name="email" required />
                                    </div>
                                </div>
                                <div className="field">
                                    <input type="text" placeholder="Subject" name="subject" required />
                                </div>
                                <div className="field textarea">
                                    <textarea cols="30" rows="10" placeholder="Describe project...." name="message" required></textarea>
                                </div>
                                <div className="button">
                                    <button type="submit">Send message</button>
                                </div>
                            </form>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact