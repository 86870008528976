import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

function Skills() {
    return (
        <section className="skills" id="skills">
            <div className="max-width">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                    <h2 className="title">My skills</h2>
                </ScrollAnimation>
                <div className="skills-content">
                    <div className="column left">
                        <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                            <div className="text">My creative skills & experiences.</div>
                            <p>
                                Along with my technical skills, I have a strong background in design and creative thinking.
                                I have a passion for creating beautiful and functional websites and applications.
                            </p>
                        </ScrollAnimation>
                    </div>
                    <div className="column right">
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                            <div className="bars">
                                <div className="info">
                                    <span>HTML</span>
                                    <span>90%</span>
                                </div>
                                <div className="line html"></div>
                            </div>
                            <div className="bars">
                                <div className="info">
                                    <span>CSS</span>
                                    <span>85%</span>
                                </div>
                                <div className="line css"></div>
                            </div>
                            <div className="bars">
                                <div className="info">
                                    <span>Javascript</span>
                                    <span>70%</span>
                                </div>
                                <div className="line js"></div>
                            </div>
                            <div className="bars">
                                <div className="info">
                                    <span>Python</span>
                                    <span>40%</span>
                                </div>
                                <div className="line python"></div>
                            </div>
                            <div className="bars">
                                <div className="info">
                                    <span>Linux</span>
                                    <span>45%</span>
                                </div>
                                <div className="line linux"></div>
                            </div>
                            <div className="bars">
                                <div className="info">
                                    <span>MySQL</span>
                                    <span>50%</span>
                                </div>
                                <div className="line mysql"></div>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills