import ScrollAnimation from 'react-animate-on-scroll'

function Services() {
    return (
        <section className="services" id="services">
            <div className="max-width">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                    <h2 className="title">My services</h2>
                </ScrollAnimation>
                <div className="serv-content">
                    <div className="card">
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                            <div className="box">
                                <i className="fas fa-code"></i>
                                <div className="text">Web Design</div>
                                <p>
                                    I have a passion for creating beautiful and functional websites and applications.
                                </p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="card">
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                            <div className="box">
                                <i className="fas fa-chart-line"></i>
                                <div className="text">Advertise</div>
                                <p>
                                    I have a passion for creating beautiful and functional websites and applications.
                                </p>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="card">
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                            <div className="box">
                                <i className="fas fa-paint-brush"></i>
                                <div className="text">App Design</div>
                                <p>
                                    I have a passion for creating beautiful and functional websites and applications.
                                </p>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services