import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <section className='home' id="home">
            <video className="video-slide h-100 d-block" autoPlay={true} muted={true} id="fullscreen-video" loop="loop">
                <source src="./assets/videos/home_background.mp4" type="video/mp4" />
            </video>
            <div className="max-width">
                <div className="home-content">
                    <div className="text-1">Hello, My name is</div>
                </div>
                <div className="home-content">
                    <div className="text-2">Hemant Chettri</div>
                </div>
                <div className="home-content">
                    <div className="text-3">And I'm a <span className="typing"></span></div>
                    <Link to="#">Hire me</Link>
                </div>
            </div>
        </section>
    )
}

export default Home;