import { toast } from 'react-toastify';

const showToast = (message, type, position = "top-center") => {
    toast(message, {
        type: type,
        position: position,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        newestOnTop: true,
        rtl: false,
        pauseOnVisibilityChange: true,
    });
}


export default showToast;