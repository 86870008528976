import React from 'react';
import About from './aboutme';
import Home from './home';
import Services from './services';
import Skills from './skills';
import Teams from './teams';
import Contact from './contact';
import ScrollAnimation from 'react-animate-on-scroll';

function Pages() {
    return (
        <>
            <Home />
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <About />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Services />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Skills />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Teams />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Contact />
            </ScrollAnimation>
        </>
    )
}

export default Pages;