import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

function About() {
    return (
        <section className="about" id="about">
            <div className="max-width">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                    <h2 className="title">About me</h2>
                </ScrollAnimation>
                <div className="about-content">
                    <div className="column left">
                        <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                            <img src="./Assets/Images/about__me.jpg" alt="" srcSet="" />
                        </ScrollAnimation>
                    </div>
                    <div className="column right">
                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                            <div className="text">
                                I'm Hemant and I'm a <span className="typing-2"></span>
                            </div>
                            <p>
                                I have a passion for creating beautiful and functional websites and applications.
                                I have a passion for creating beautiful and functional websites and applications.
                            </p>
                            <Link to="./assets/cv/hemantChettri_resume.pdf" target="_blank" download>Download CV</Link>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default About